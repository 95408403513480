// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-berufseinstieg-tsx": () => import("./../../../src/pages/berufseinstieg.tsx" /* webpackChunkName: "component---src-pages-berufseinstieg-tsx" */),
  "component---src-pages-film-tsx": () => import("./../../../src/pages/film.tsx" /* webpackChunkName: "component---src-pages-film-tsx" */),
  "component---src-pages-galerie-tsx": () => import("./../../../src/pages/galerie.tsx" /* webpackChunkName: "component---src-pages-galerie-tsx" */),
  "component---src-pages-geschichte-tsx": () => import("./../../../src/pages/geschichte.tsx" /* webpackChunkName: "component---src-pages-geschichte-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-oeffentlicher-verkehr-tsx": () => import("./../../../src/pages/oeffentlicher-verkehr.tsx" /* webpackChunkName: "component---src-pages-oeffentlicher-verkehr-tsx" */),
  "component---src-pages-vernetzungsgruppe-tsx": () => import("./../../../src/pages/vernetzungsgruppe.tsx" /* webpackChunkName: "component---src-pages-vernetzungsgruppe-tsx" */),
  "component---src-pages-zeitung-mittendrin-tsx": () => import("./../../../src/pages/zeitung-mittendrin.tsx" /* webpackChunkName: "component---src-pages-zeitung-mittendrin-tsx" */)
}

